
@font-face {
    font-family: 'Plexes Pro';
    src: url('fonts/PlexesPro-Medium.woff2') format('woff2'),
        url('fonts/PlexesPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
  font-family: 'Helvetica Now';
  src: url('fonts/HelveticaNowDisplayRegular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0 auto;
  width: 100%;
  color: #000;
  background-color: #FFF;
  font-family: "Helvetica";
}

/* hide numeric "spinners" on input type=number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.gx-container .cross {
  margin-right: 15px;
  margin-top: 3px;
}
.gx-container .cross:before,
.gx-container .cross:after {
  content: '';
  border-top: 2px solid #3E474F;
  width: 15px;
  display: block;
  margin-top: 15px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.gx-container .cross:after {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
  margin-top: -2px;
}

.MuiButton-root:hover {
  background-color: #3E474F;
}
